import React from 'react';
import logo from '../../img/improviz_logo.png';
import { useNavigate } from "react-router-dom";

export function Header() {

    const nav = useNavigate();

    return(
        <header id="main-header">
            <div className="inner">
                <a href="#" onClick={ (e) => { e.preventDefault(); nav("/");} }>
                    <img src={logo} id="logo" />
                </a>
            </div>
        </header>
    );
}
