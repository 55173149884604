import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './language-menu.scss';

export function LanguageMenu() {
    const { i18n } = useTranslation('common');
    const [showNav, setShowNav] = useState(false);

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        setShowNav(false);
      };

    const options = Object.getOwnPropertyNames(i18n.options.resources).filter(obj => obj !== i18n.language);

    const showClass = showNav ? '' : 'hide-nav';

    return(
        <div id="language-menu">
            <ul id="languages-list" className={showClass}>
                <li key={i18n.language}><a href="#" onClick={(e) => setShowNav(!showNav)}>{i18n.language.toUpperCase()}</a></li>
                {options.map((lang) => (
                    <li key={lang}><a href="#" onClick={(e) => {e.preventDefault(); changeLanguage(lang);}}>{lang.toUpperCase()}</a></li>
                ))}
            </ul>
        </div>
    );
}
