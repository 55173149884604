import { useTranslation } from "react-i18next";
import './startform.scss';
import { ChangeEvent, useState } from "react";
import mic from '../../img/improviz_loading.png'
import { getHttpClient } from "../../store";
import configData from "../../config.json";
import { useDispatch } from "react-redux";
import { PopupMessageTypeEnum } from "../popupMessage/PopupMessageTypeEnum";
import { getErrorMessage } from "../../helpers/ServiceErrorHelper";

export function StartForm() {
    const {t} = useTranslation('common');
    const { i18n } = useTranslation('common');
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        name: '',
      });
    
      const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = new FormData(e.target);
        if (validate(data)) {
            const postBody = {
                name: data.get('name') as string,
                lang: i18n.language
            }

            getHttpClient().post(configData.apis.practiceSession.start, postBody)
                .then((response) => {
                    if (response.data.practiceSession.practice_sessionid) {
                        localStorage.setItem('practiceSessionId', response.data.practiceSession.practice_sessionid);
                        dispatch({
                          type: 'improvizApp/setData',
                          payload: response.data,
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    if (errorMessage.title) {
                        dispatch({
                            type: 'popupMessage/setMessage',
                            payload: {
                                class: PopupMessageTypeEnum.ERROR,
                                title: t(errorMessage.title),
                                message: t(errorMessage.message),
                            },
                        });
                    }
                });
        }
      };

      const validate = (data: FormData) => {
          const isValid = true;
          return isValid;
      }

    return (
        <form onSubmit={handleSubmit} id="start-form">
            <div id="mic-image">
                <img src={mic} className="mic"/>
            </div>
            <p>{t("improviz.startFormExplanation")}</p>
            <div className="form-group">
                <label htmlFor="name">{t("improviz.startFormName")}</label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div id="submit">
                <button type="submit" className="btn btn-primary">{t("improviz.start")}</button>
            </div>
        </form>
    );
}
