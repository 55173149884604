import { useTranslation } from "react-i18next";
import './evaluation-settings.scss';
import { getHttpClient } from "../../store";
import configData from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../types/StateType";
import { ChangeEvent, useState } from "react";
import { PopupMessageTypeEnum } from "../popupMessage/PopupMessageTypeEnum";
import { TimeInput } from "./TimeInput";
import { TimeConversionHelper } from "../../helpers/TimeConversionHelper";
import { Form } from 'react-bootstrap';
import { getErrorMessage } from "../../helpers/ServiceErrorHelper";


export function EvaluationSettings() {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const improvizState = useSelector((state: IState) => {
        return state.improvizApp.data;
    });

    const [formData, setFormData] = useState({
        evaluation: Boolean(improvizState.practiceSession.settings.evaluation),
        evaluation_type: String(improvizState.practiceSession.settings.evaluation_type),
        green: String(improvizState.practiceSession.settings.feedback_green),
        yellow: String(improvizState.practiceSession.settings.feedback_yellow),
        red: String(improvizState.practiceSession.settings.feedback_red),
        max: String(improvizState.practiceSession.settings.feedback_max),
      });
    
      const handleChange = (colour: string, value: string) => {
        setFormData({
          ...formData,
          [colour]: value
        });
      };

      const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        formData.evaluation = e.target.checked;
        setFormData({
          ...formData,
        });
        submitForm();
      };

      const handleSelectEvalType = (type: string) => {
        formData.evaluation_type = type;
        setFormData({
          ...formData
        });
        submitForm();
      }
    
      const handleSubmit = () => {
        submitForm();
      };

      
      const submitForm = () => {
        if (validate()) {
            const postBody = {
                practice_sessionid: improvizState.practiceSession.practice_sessionid,
                data: formData,
            }

            getHttpClient().post(configData.apis.practiceSession.evaluationSettings, postBody)
                .then((response) => {
                    if (response.data.practiceSession) {
                        dispatch({
                          type: 'improvizApp/setPracticeSession',
                          payload: response.data.practiceSession,
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    if (errorMessage.title) {
                        dispatch({
                            type: 'popupMessage/setMessage',
                            payload: {
                                class: PopupMessageTypeEnum.ERROR,
                                title: t(errorMessage.title),
                                message: t(errorMessage.message),
                            },
                        });
                    }
                });
        }
      };



    const validate = () => {
        const greenSeconds = TimeConversionHelper.timeToSeconds(String(formData.green));
        const yellowSeconds = TimeConversionHelper.timeToSeconds(String(formData.yellow));
        const redSeconds = TimeConversionHelper.timeToSeconds(String(formData.red));
        // Make sure speaker does not already exist
        if (greenSeconds > yellowSeconds){
            return false;
        }
        if (yellowSeconds > redSeconds){
            return false;
        }
        return true;
    }

    const evalOptions = ['group', 'individual'];

    return (
        
        <div id="evaluation-settings" className="settings-form">
            <h2>
                <label>{t('improviz.evaluations')}</label>
            </h2>
            <div className="content">
                <Form id="evaluation-settings-form">
                    
                    <div className="form-group">
                        <Form.Check
                            type="switch"
                            id="eval-type"
                            label={t('improviz.evaluateSpeakers')}
                            value="evaluation"
                            checked={formData.evaluation}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    {improvizState.practiceSession.settings.evaluation &&
                    <div>
                            
                        <div className="form-group">
                            <label htmlFor="eval-type">{t("improviz.evaluationType")}</label>
                            <Form.Select
                                name="eval-type"
                                value={formData.evaluation_type}
                                onChange={(e) => handleSelectEvalType(e.target.value)}
                            >
                            {evalOptions.map((option) => (
                                <option key={option} value={option}>{t("improviz." + option)}</option>
                            ))}
                            </Form.Select>
                        </div>

                        <div className="form-group green">
                            <label htmlFor="green">{t("improviz.green")}</label>
                            <TimeInput
                                className="green"
                                id="time-green"
                                name="green"
                                value={formData.green}
                                max={TimeConversionHelper.timeToSeconds(formData.yellow)}
                                onChange={(e) => handleChange('green', e.target.value)}
                                onBlur={handleSubmit}
                                required
                            />
                        </div>
                        <div className="form-group yellow">
                            <label htmlFor="yellow">{t("improviz.yellow")}</label>
                            <TimeInput
                                className="yellow"
                                id="time-yellow"
                                name="yellow"
                                value={formData.yellow}
                                min={TimeConversionHelper.timeToSeconds(formData.green)}
                                max={TimeConversionHelper.timeToSeconds(formData.red)}
                                onChange={(e) => handleChange('yellow', e.target.value)}
                                onBlur={handleSubmit}
                                required
                            />
                        </div>
                        <div className="form-group red">
                            <label htmlFor="red">{t("improviz.red")}</label>
                            <TimeInput
                                className="red"
                                id="time-red"
                                name="red"
                                value={formData.red}
                                min={TimeConversionHelper.timeToSeconds(formData.yellow)}
                                onChange={(e) => handleChange('red', e.target.value)}
                                onBlur={handleSubmit}
                                required
                            />
                        </div>
                    </div>
                    }
                </Form>
            </div>
        </div>
    );
}
