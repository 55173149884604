import {IAppData} from "../types/AppDataType";
import {ISpeaker} from "../types/SpeakerType";

export class SpeakerHelper {
    appData: IAppData;

    constructor(appData: IAppData) {
        this.appData = appData;
    }

    getTopRole(speaker: ISpeaker): string | null {
        if (speaker.admin) {
            return 'admin';
        }
        return null;
    }

    getActiveCount(): number {
        return this.appData.speakers.filter((x) => x.status == 1).length;
    }
}