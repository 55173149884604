import React from 'react';
import {TemplateWrapper} from "../../templateWrapper/TemplateWrapper";
import {LoginForm} from "../../loginForm/LoginForm";


function LoginPage() {

    return (
        <div id="login-page">
            <TemplateWrapper>
                <LoginForm />
            </TemplateWrapper>
        </div>
    );
}

export default LoginPage;
