import { useTranslation } from "react-i18next";
import { getHttpClient } from "../../store";
import configData from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../types/StateType";
import { useState } from "react";
import { PopupMessageTypeEnum } from "../popupMessage/PopupMessageTypeEnum";
import './speech-timings.scss';
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "../../helpers/ServiceErrorHelper";

export function EndSession() {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const nav = useNavigate();
    const improvizState = useSelector((state: IState) => {
        return state.improvizApp.data;
    });

    const [showConf, setShowConf] = useState(false);

      const end = () => {
        
            localStorage.removeItem('practiceSessionId');
            dispatch({
            type: 'improvizApp/clearData',
            payload: null,
            });

            const postBody = {
                practice_sessionid: improvizState.practiceSession.practice_sessionid,
            }

            getHttpClient().post(configData.apis.practiceSession.end, postBody)
                .then((response) => {
                    nav('/');
                })
                .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    if (errorMessage.title) {
                        dispatch({
                            type: 'popupMessage/setMessage',
                            payload: {
                                class: PopupMessageTypeEnum.ERROR,
                                title: t(errorMessage.title),
                                message: t(errorMessage.message),
                            },
                        });
                    }
                });
      };


    return (
        
        <div id="end-session" className="settings-form">
            <h2>
                <label>{t('improviz.endSession')}</label>
            </h2>
            <div className="content">
                <div id="end-buton">
                    <button className="btn btn-danger" onClick={(e) => setShowConf(!showConf)}>{t('improviz.endSession')}</button>
                </div>
                {showConf &&
                    <div id="end-session-confirmation-form">
                        <p>{t('improviz.endSure')}</p>
                        <button className="btn btn-secondary" onClick={(e) => setShowConf(false)}>{t('improviz.endNo')}</button>
                        <button className="btn btn-danger" onClick={end}>{t('improviz.endYes')}</button>
                    </div>
                }
            </div>
        </div>
    );
}
