import React from 'react';
import {TemplateWrapper} from "../../templateWrapper/TemplateWrapper";
import {ForgotPasswordForm} from "../../loginForm/ForgotPasswordForm";


function ForgotPasswordPage() {

    return (
        <div id="login-page">
            <TemplateWrapper>
                <ForgotPasswordForm />
            </TemplateWrapper>
        </div>
    );
}

export default ForgotPasswordPage;
