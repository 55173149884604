import React from 'react';
import './template-header.scss';
import logo from '../../img/improviz_logo.png';
import { useNavigate } from "react-router-dom";
import { MainNav } from '../nav/MainNav';
import { LanguageMenu } from './LanguageMenu';

export function TemplateHeader() {

    const nav = useNavigate();

    return(
        <header id="main-header">
            <div className="inner">
                <a href="#" onClick={ (e) => { e.preventDefault(); nav("/");} }>
                    <img src={logo} id="logo" />
                </a>
                {/*<MainNav/>*/}
                <LanguageMenu />
            </div>
        </header>
    );
}
