import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { getHttpClient } from '../../store';
import configData from "../../config.json";
import { ITag } from '../../types/TagType';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from '../../helpers/ServiceErrorHelper';
import { PopupMessageTypeEnum } from '../popupMessage/PopupMessageTypeEnum';

export function TopicsCount() {

    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const [tags, setTags] = useState<{language: String, tags: {tag: ITag, count: number}[]}[]>([]);

    useEffect(() => {
        getHttpClient().get(configData.apis.admin.topicsCount)
            .then((response) => {
                setTags(response.data.counts);
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }, []);
    
    const evenOdd = (i: number) => {
        return i % 2 == 0 ? ' even' : ' odd';
    }

    const total = (language: string): number => {
        let t = 0;
        const languageTags = tags.filter((x) => x.language === language)[0].tags;
        for (let i in languageTags) {
            t += languageTags[i].count;
        }
        return t;
    }

    return(
        <div id="topics-count">
            <h2>{t('admin.topicsCount')}</h2>
            {tags.length &&
            <div>
            <h3>{t('admin.english')}</h3>
            <ul id="tags-list">
                {tags.filter((x) => x.language === 'en')[0].tags.map((count, i) => (
                    <li key={count.tag.tagid} className={"tag" + evenOdd(i)}>
                        <span className="tag-title">{count.tag.tag}: {count.count}</span>
                    </li>
                ))}
                <li>Total: {total('en')}</li>
            </ul>
            <h3>{t('admin.french')}</h3>
            <ul id="tags-list">
                {tags.filter((x) => x.language === 'fr')[0].tags.map((count, i) => (
                    <li key={count.tag.tagid} className={"tag" + evenOdd(i)}>
                        <span className="tag-title">{count.tag.tag}: {count.count}</span>
                    </li>
                ))}
                <li>Total: {total('fr')}</li>
            </ul>
            </div>
            }
        </div>
    );
}
