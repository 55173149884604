export class TimeConversionHelper {

    static normalize(time: string): string {
        return TimeConversionHelper.secondsToTime(TimeConversionHelper.timeToSeconds(time));
    }

    static timeToSeconds(time: string): number {
        let isNegative = false;
        if (time.match(/^-/)) {
            isNegative = true;
            time.replace(/^-/, '');
        }
        const parts = time.split(':');
        parts.reverse();
        let seconds = parseInt(parts[0]);
        if (typeof parts[1] !== 'undefined') {
            seconds += parseInt(parts[1]) * 60;
        }
        if (typeof parts[2] !== 'undefined') {
            seconds += parseInt(parts[2]) * 3600;
        }
        if (isNegative) {
            seconds = 0 - seconds;
        }
        return seconds;
    }
    static secondsToTime(seconds: number, leadingZeroes: boolean = false): string {
        let isNegative = false;
        if (seconds < 0) {
            seconds = 0 - seconds;
            isNegative = true;
        }
        const hours: number = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes: number = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        let timeOutput: string = '';
        if (hours > 0) {
            timeOutput += hours.toString() + ':';
        }
        if (hours > 0 || minutes > 0 || leadingZeroes) {
            if (hours > 0) {
                timeOutput += minutes.toString().padStart(2, '0') + ':';
            }
            else {
                timeOutput += minutes.toString() + ':';
            }
        }
        if (hours > 0 || minutes > 0 || leadingZeroes) {
            timeOutput += seconds.toString().padStart(2, '0');
        }
        else {
            timeOutput += seconds.toString();
        }
        if (isNegative) {
            timeOutput = '-' + timeOutput;
        }
        return timeOutput;
    }
}