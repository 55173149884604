import { TemplateWrapper } from "../../templateWrapper/TemplateWrapper";
import background from '../../../img/curtains_dark.jpg';
import what from '../../../img/icons_what.png';
import wordBubbles from '../../../img/icons_wb.png';
import mic from '../../../img/icons_mic.png';
import './homepage.scss';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function HomePage() {
    const nav = useNavigate();
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const psid = localStorage.getItem('practiceSessionId');

    const startNew = () => {
        localStorage.removeItem('practiceSessionId');
        dispatch({
            type: 'improvizApp/clearData',
            payload: null,
        });
        nav("/practice");
    }

    return (
            <div id="home">
                <TemplateWrapper>
                    <div id="hero" style={{ backgroundImage: `url(${background})` }}>
                        <h1>{t('home.title')}</h1>
                        <p>{t('home.line1')}<br />{t('home.line2')}</p>
                        {!psid &&
                        <p><a href="#" className="btn btn-primary" onClick={ (e) => { e.preventDefault(); nav("/practice");} }>{t('home.start')}</a></p>
                        }
                        {psid &&
                        <p>
                            <a href="#" className="btn btn-primary" onClick={ (e) => { e.preventDefault(); nav("/practice");} }>{t('home.continue')}</a>
                            <a href="#" className="btn btn-danger" onClick={ (e) => { e.preventDefault(); startNew();} }>{t('home.startNew')}</a>
                        </p>
                        }
                    </div>
                    <div id="home-info">
                        <div className="icon"><img src={what} /></div>
                        <h2>{t('home.whattitle')}</h2>
                        <p>{t('home.what1')}</p>
                        <p>{t('home.what2')}</p>
                        <div className="icon"><img src={wordBubbles} /></div>
                        <h2>{t('home.whytitle')}</h2>
                        <p>{t('home.why1')}</p>
                        <div className="icon"><img src={mic} /></div>
                        <h2>{t('home.howtitle')}</h2>
                        <p>{t('home.how1')}</p>
                        <p>{t('home.how2')}</p>
                        {/*<p>{t('home.how3')}</p>@TODO put this back when login and personal topics are working*/}
                    </div>
                </TemplateWrapper>
            </div>
    );
}

export default HomePage;