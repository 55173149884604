import './time-input.scss';
import { ChangeEvent, useState } from "react";
import { TimeConversionHelper } from "../../helpers/TimeConversionHelper";
import { useTranslation } from 'react-i18next';

interface props {
    className?: string,
    id?: string,
    name: string,
    value: string,
    min?: number,
    max?: number,
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onBlur?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    required?: boolean
}

export function TimeInput(props: props) {
    const {t} = useTranslation('common');

    const [value, setValue] = useState(TimeConversionHelper.normalize(props.value));

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    }
    const handleBlur = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(TimeConversionHelper.normalize(e.target.value));
        if (props.onBlur) {
            props.onBlur(e);
        }
    }
    const errorClass = (): string => {
        if (props.min && TimeConversionHelper.timeToSeconds(value) < props.min) {
            return ' error';
        }
        if (props.max && TimeConversionHelper.timeToSeconds(value) > props.max) {
            return ' error';
        }
        return '';
    }
    const errorMessage = (): string => {
        if (props.min && TimeConversionHelper.timeToSeconds(value) < props.min) {
            return t("timeInput.min", {min: TimeConversionHelper.secondsToTime(props.min)})
        }
        if (props.max && TimeConversionHelper.timeToSeconds(value) > props.max) {
            return t("timeInput.max", {max: TimeConversionHelper.secondsToTime(props.max)})
        }
        return '';
    }
    
    return (
        <div id={props.id} className={"time-input " + props.className + errorClass()}>
            <div className="form-control">
                <input
                    type="text"
                    name={props.name}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={props.required}
                    
                />
            </div>
            <div className="error-message">
                {errorMessage()}
            </div>
        </div>
    );
}
