import { EvaluationType } from "../enums/EvaluationTypeEnum";
import {IAppData} from "../types/AppDataType";
import {ISpeaker} from "../types/SpeakerType";

export function getSpeaker(improvizState: IAppData): ISpeaker | undefined {
    if (!improvizState.speakers) {
        return undefined;
    }
    return improvizState.speakers.find((p) => p.speakerid === improvizState.speech.speakerid);
}

export function getEvaluator(improvizState: IAppData): ISpeaker | undefined {
    if (improvizState.practiceSession.settings.evaluation_type === EvaluationType.GROUP) {
        return undefined;
    }
    if (!improvizState.speakers) {
        return undefined;
    }
    return improvizState.speakers.find((p) => p.speakerid === improvizState.speech.eval_speakerid);
}