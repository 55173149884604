import React from 'react';
import { useTranslation } from "react-i18next";

export function Footer() {
    const {t} = useTranslation('common');

    return(
        <footer>
            <div className="inner">
                &copy; {t('footer.copyright')} {new Date().getFullYear()}.
                <br />{t('footer.allrights')}
            </div>
        </footer>
    );
}
