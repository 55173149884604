import React from 'react';
import {TemplateWrapper} from "../templateWrapper/TemplateWrapper";
import { TopicsCount } from './TopicsCount';
import { PracticeSessions } from './PracticeSessions';


function Admin() {

    return (
        <div id="admin">
            <TemplateWrapper>
                <TopicsCount />
                <PracticeSessions />
            </TemplateWrapper>
        </div>
    );
}

export default Admin;
