import { useTranslation } from "react-i18next";
import './tags.scss';
import { getHttpClient } from "../../store";
import configData from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../types/StateType";
import { PopupMessageTypeEnum } from "../popupMessage/PopupMessageTypeEnum";
import { ITag } from "../../types/TagType";
import { getErrorMessage } from "../../helpers/ServiceErrorHelper";


export function TagsSelector() {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const improvizState = useSelector((state: IState) => {
        return state.improvizApp.data;
    });


    const removeTag = (tagid: number) => {

        if (getActiveTags().length <= 1) {
            return;
        }

        const postBody = {
            practice_sessionid: improvizState.practiceSession.practice_sessionid,
            tagid: tagid
        }
        getHttpClient().post(configData.apis.practiceSession.removeTag, postBody)
        .then((response) => {
            if (response.data.practiceSession.practice_sessionid) {
                dispatch({
                  type: 'improvizApp/setPracticeSession',
                  payload: response.data.practiceSession,
                });
            }
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            if (errorMessage.title) {
                dispatch({
                    type: 'popupMessage/setMessage',
                    payload: {
                        class: PopupMessageTypeEnum.ERROR,
                        title: t(errorMessage.title),
                        message: t(errorMessage.message),
                    },
                });
            }
        });
    }

    const addTag = (tagid: number) => {

        const postBody = {
            practice_sessionid: improvizState.practiceSession.practice_sessionid,
            tagid: tagid
        }
        getHttpClient().post(configData.apis.practiceSession.addTag, postBody)
        .then((response) => {
            if (response.data.practiceSession.practice_sessionid) {
                dispatch({
                  type: 'improvizApp/setPracticeSession',
                  payload: response.data.practiceSession,
                });
            }
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            if (errorMessage.title) {
                dispatch({
                    type: 'popupMessage/setMessage',
                    payload: {
                        class: PopupMessageTypeEnum.ERROR,
                        title: t(errorMessage.title),
                        message: t(errorMessage.message),
                    },
                });
            }
        });
    }

    const getActiveTags = (): ITag[] => {
        const tags = [];
        for (let tag of improvizState.tags) {
            if (improvizState.practiceSession.tags.findIndex((x) => x.tagid === tag.tagid) > -1) {
                tags.push(tag);
            }
        }
        return tags;
    }
    const getAvailableTags = (): ITag[] => {
        const tags = [];
        for (let tag of improvizState.tags) {
            if (improvizState.practiceSession.tags.findIndex((x) => x.tagid === tag.tagid) === -1) {
                tags.push(tag);
            }
        }
        return tags;
    }

    const activeTags = getActiveTags();
    const availableTags = getAvailableTags();

    return (
        
        <div id="tags-selector" className="settings-form">
            <h2>
                <label>{t('improviz.tags')}</label>
            </h2>
            <div className="content">
                <p>{t('improviz.tagsInstructions')}</p>
                <h3>{t('improviz.activeTags')}</h3>
                <ul id="active-tags" className="tags-list">
                    {activeTags.map((tag) => (
                        <li key={tag.tagid} onClick={(e) => removeTag(tag.tagid)}>{tag.tag} -</li>
                    ))}
                </ul>
                {availableTags.length > 0 &&
                <div>
                <h3>{t('improviz.availableTags')}</h3>
                <ul id="available-tags" className="tags-list">
                    {availableTags.map((tag) => (
                        <li key={tag.tagid} onClick={(e) => addTag(tag.tagid)}>{tag.tag} +</li>
                    ))}
                </ul>
                </div>
                }
            </div>
        </div>
    );
}
