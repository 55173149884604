import React, {ReactNode} from 'react';
import {TemplateHeader} from "./TemplateHeader";
import {TemplateFooter} from "./TemplateFooter";
import PopupMessage from "../popupMessage/PopupMessage";
interface props {
    children: ReactNode;
}
export function TemplateWrapper(props: props) {

    return(
        <div>
            <TemplateHeader/>
            <PopupMessage/>
                <div id="main">
                {props.children}
            </div>
            <TemplateFooter/>
        </div>
    );
}
