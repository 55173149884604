import { useTranslation } from "react-i18next";
import './improviz.scss';
import { ImpState } from "../../enums/ImpStateEnum";
import { getHttpClient } from "../../store";
import configData from "../../config.json";
import { StartForm } from "./StartForm";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../types/StateType";
import { getEvaluator, getSpeaker } from "../../helpers/ImprovizHelper";
import { TimeConversionHelper } from "../../helpers/TimeConversionHelper";
import { SpeakerHelper } from "../../helpers/SpeakerHelper";
import { getErrorMessage } from "../../helpers/ServiceErrorHelper";
import { PopupMessageTypeEnum } from "../popupMessage/PopupMessageTypeEnum";

interface props {
    timeElapsed: number|null,
    isTimer: boolean,
}

export function SpeechDisplay(props: props) {
    const {t} = useTranslation('common');
    const { i18n } = useTranslation('common');
    const dispatch = useDispatch();
    const improvizState = useSelector((state: IState) => {
        return state.improvizApp.data;
    });
    const speakerHelper = new SpeakerHelper(improvizState);


    const actionButtons = () => {
        if (improvizState.practiceSession.state === ImpState.READY) {
            return (
                <div id="button-wrapper" className={improvizState.practiceSession.state}>
                    <button className="btn btn-primary" onClick={clickStart}>{t("improviz.start")}</button>
                </div>
            );
        }
        if (
            improvizState.practiceSession.state === ImpState.SPEAKING
            || improvizState.practiceSession.state === ImpState.EVALUATION
            || improvizState.practiceSession.state === ImpState.PAUSED
        ) {
            return (
                <div id="button-wrapper" className={`live ${improvizState.practiceSession.state} two-buttons`}>
                    <button className="btn btn-secondary button-1" onClick={clickPause}>{improvizState.practiceSession.state === ImpState.PAUSED ? t("improviz.continue") : t("improviz.pause")}</button>
                    <button className="btn btn-secondary button-2" onClick={clickEnd}>{t("improviz.next")}</button>
                </div>
            );
        }
    }

    const clickStart = () => {
        const postBody = {
            practice_sessionid: improvizState.practiceSession.practice_sessionid,
            lang: i18n.language
        }
        getHttpClient().post(configData.apis.speech.start, postBody)
            .then((response) => {
                dispatch({
                    type: 'improvizApp/setSpeech',
                    payload: response.data.speech,
                  });
                  dispatch({
                      type: 'improvizApp/setPracticeSession',
                      payload: response.data.practiceSession,
                    });
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }

    const clickPause = () => {
        const postBody = {
            practice_sessionid: improvizState.practiceSession.practice_sessionid,
            lang: i18n.language
        }
        getHttpClient().post(configData.apis.speech.pause, postBody)
            .then((response) => {
                dispatch({
                    type: 'improvizApp/setSpeech',
                    payload: response.data.speech,
                  });
                  dispatch({
                      type: 'improvizApp/setPracticeSession',
                      payload: response.data.practiceSession,
                    });
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }

    const clickEnd = () => {
        const postBody = {
            practice_sessionid: improvizState.practiceSession.practice_sessionid,
            lang: i18n.language
        }
        getHttpClient().post(configData.apis.speech.end, postBody)
            .then((response) => {
                dispatch({
                    type: 'improvizApp/setSpeech',
                    payload: response.data.speech,
                  });
                  dispatch({
                      type: 'improvizApp/setPracticeSession',
                      payload: response.data.practiceSession,
                    });
                    if (!improvizState.speech.eval_started && improvizState.practiceSession.settings.evaluation && speakerHelper.getActiveCount() > 1) {
                        evaluation();
                    } else {
                        next();
                    }
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }

    const evaluation = () => {
        const postBody = {
            practice_sessionid: improvizState.practiceSession.practice_sessionid,
            lang: i18n.language
        }
        getHttpClient().post(configData.apis.practiceSession.loadEvaluation, postBody)
            .then((response) => {
                dispatch({
                    type: 'improvizApp/setSpeech',
                    payload: response.data.speech,
                  });
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }

    const next = () => {
        const postBody = {
            practice_sessionid: improvizState.practiceSession.practice_sessionid,
            lang: i18n.language
        }
        getHttpClient().post(configData.apis.speech.next, postBody)
            .then((response) => {
                dispatch({
                    type: 'improvizApp/setSpeech',
                    payload: response.data.speech,
                  });
                  dispatch({
                      type: 'improvizApp/setPracticeSession',
                      payload: response.data.practiceSession,
                    });
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }

    const getSpeakerName = (): string => {
        if (improvizState.speech && !improvizState.speech.ended) {
            const speaker = getSpeaker(improvizState);
            return speaker ? speaker.name : '';
        }
        if (improvizState.speech && improvizState.speech.ended) {
            const speaker = getEvaluator(improvizState);
            return speaker ? speaker.name : '';
        }
        return '';
    }

    const getTopic = (): string => {
        if (improvizState.speech && !improvizState.speech.ended) {
            return improvizState.speech.topic.title;
        }
        if (
            improvizState.speech
            && improvizState.practiceSession.settings.evaluation
        ) {
            const speaker = getSpeaker(improvizState);
            const name = speaker ? speaker.name : '';
            return t("improviz.evaluating", {name});
        }
        return '';
    }

    const getTime = (): string => {
        if (
            improvizState.practiceSession.state === ImpState.SPEAKING
            || improvizState.practiceSession.state === ImpState.EVALUATION
            || improvizState.practiceSession.state === ImpState.PAUSED
        ) {
            return props.timeElapsed ? TimeConversionHelper.secondsToTime(props.timeElapsed) : "";
        }
        return "";
    }

    const appClass = improvizState.practiceSession.state;
    const isTimerClass = props.isTimer ? " is-timer" : "";

    return (
        <div id="app-main" className={`${appClass}${isTimerClass}`}>
            {improvizState.practiceSession.state === ImpState.NEW &&
            (
                <div id="new">
                    <StartForm />
                </div>
            )}
            {improvizState.practiceSession.state !== ImpState.NEW &&
            (
            <div>
                <div id="row1">
                    <div id="round" className="speech-info">
                        <label className="center">{t('improviz.round')}</label>
                        <div className="content center">
                            <div className="inner">{improvizState.practiceSession.round}</div>
                        </div>
                    </div>
                    <div id="speaker" className="speech-info">
                        <label>{t('improviz.speaker')}</label>
                        <div className="content">
                            <div className="inner">{getSpeakerName()}</div>
                        </div>
                    </div>
                </div>
                <div id="row2">
                    <div id="topic" className="speech-info">
                        <label>{t('improviz.topic')}</label>
                        <div className="content">
                            <div className="inner">{getTopic()}</div>
                        </div>
                    </div>
                </div>
                <div id="row3">
                    <div id="time" className="speech-info">
                        <label className="center">{t('improviz.time')}</label>
                        <div className="content center">
                            <div className="inner">{getTime()}</div>
                        </div>
                    </div>
                </div>
                <div id="actions" className={improvizState.practiceSession.state}>
                    {actionButtons()}
                </div>
            </div>
            )}
        </div>
    );
}
