import { useSelector } from "react-redux";
import { IState } from "../../types/StateType";
import { ParticipantsSettings } from "./ParticipantsSettings";
import { SpeechTimings } from "./SpeechTimings";
import { EvaluationSettings } from "./EvaluationSettings";
import { TagsSelector } from "./TagsSelector";
import { EndSession } from "./EndSession";


export function SettingsDisplay() {
    const improvizState = useSelector((state: IState) => {
        return state.improvizApp.data;
    });

    return (
        <div id="app-settings">
            <ParticipantsSettings />
            <TagsSelector />
            <SpeechTimings />
            {improvizState.speakers.length > 1 &&
                <EvaluationSettings />
            }
            <EndSession />
        </div>
    );
}
