import './improviz.scss';
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SpeechDisplay } from "./SpeechDisplay";
import { useSelector } from "react-redux";
import { IState } from "../../types/StateType";
import { useEffect, useState } from "react";
import { SpeechTimeHelper } from "../../helpers/SpeechTimeHelper";
import { ImpState } from "../../enums/ImpStateEnum";
import { GearFill, HouseFill, StopwatchFill } from "react-bootstrap-icons";
import { AppTab } from "../../enums/AppTabEnum";
import { SettingsDisplay } from "./SettingsDisplay";
import PopupMessage from '../popupMessage/PopupMessage';

function Improviz() {

    const improvizState = useSelector((state: IState) => {
        return state.improvizApp.data;
    });
    const serverTime = useSelector((state: IState) => {
        return state.serverTime;
    });
    const [timeElapsed, setTimeElapsed] = useState<number|null>(null);
    const [activeTab, setActiveTab] = useState<AppTab>(AppTab.HOME);

    const speechTime = new SpeechTimeHelper(improvizState, serverTime.offset);


    const tabClass = (tab: AppTab): string => {
        return tab === activeTab ? "active" : "";
    }

    useEffect(() => {
        const updateTime = () => {
            const currentTime = speechTime.getElapsedTime();
            if (improvizState.practiceSession.state !== ImpState.PAUSED && currentTime !== null) {
                if (currentTime !== timeElapsed) {
                    setTimeElapsed(currentTime);
                }
            }
        }
        if (improvizState.speech && serverTime.localTime > 0) {
            const interval = setInterval(updateTime, 100);
            return () => clearInterval(interval);
        }
    }, [improvizState.speech, serverTime.localTime]);

    useEffect(() => {
        if (improvizState.practiceSession.state === ImpState.READY) {
            setTimeElapsed(0);
        }
    }, [improvizState.practiceSession.state]);


    const colourClass = speechTime.getColour();
    const appClass = improvizState.practiceSession.state;


    return (
            <div id="improviz">
                <Header />
                <PopupMessage/>
                {improvizState.practiceSession.state !== ImpState.NEW &&
                    <div id="tabs">
                        <ul>
                            <li className={tabClass(AppTab.HOME)} onClick={() => setActiveTab(AppTab.HOME)}>
                                <span className={`${colourClass}`}><HouseFill/></span>
                            </li>
                            <li className={tabClass(AppTab.TIMER)} onClick={() => setActiveTab(AppTab.TIMER)}>
                                <span className={`${colourClass}`}><StopwatchFill /></span>
                            </li>
                            <li className={tabClass(AppTab.SETTINGS)} onClick={() => setActiveTab(AppTab.SETTINGS)}>
                                <span className={`${colourClass}`}><GearFill /></span>
                            </li>
                        </ul>
                    </div>
                }
                <div id="color" className={`${appClass} ${colourClass}`}>
                    {activeTab === AppTab.HOME &&
                        <SpeechDisplay timeElapsed={timeElapsed} isTimer={false} />
                    }
                    {activeTab === AppTab.TIMER &&
                        <SpeechDisplay timeElapsed={timeElapsed} isTimer={true} />
                    }
                    {activeTab === AppTab.SETTINGS &&
                        <SettingsDisplay />
                    }
                </div>
                <Footer />
            </div>
    );
}

export default Improviz;