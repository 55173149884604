import { configureStore, createSlice } from '@reduxjs/toolkit';
import {HttpClient} from "../helpers/HttpClient";


const userSlice = createSlice({
    name: 'user',
    initialState: {user: undefined, token: undefined, loginComplete: false},
    reducers: {
        setUser(state, action){
            state.user = action.payload;
            state.token = action.payload.token;
            state.loginComplete = true;
        },
        setLoginComplete(state, action){
            state.loginComplete = action.payload;
        },
        logout(state, action){
            state.user = undefined;
        },
    },
});


const serverTimeSlice = createSlice({
    name: 'serverTime',
    initialState: {serverTime: 0, localTime: 0, offset: 0},
    reducers: {
        setServerTime(state, action){
            state.serverTime = new Date(action.payload).getTime();
            state.localTime = new Date().getTime();
            state.offset = new Date(action.payload).getTime() - new Date().getTime();
        },
    },
});

const myTopicsSlice = createSlice({
    name: 'myTopics',
    initialState: {myTopics: []},
    reducers: {
        setMyTopics(state, action){
            state.myTopics = action.payload;
        },
    },
});

const popupMessageSlice = createSlice({
    name: 'popupMessage',
    initialState: {class: undefined, title: undefined, message: undefined},
    reducers: {
        setMessage(state, action){
            state.class = action.payload.class;
            state.title = action.payload.title;
            state.message = action.payload.message;
        },
        clearMessage(state, action){
            state.class = undefined;
            state.title = undefined;
            state.message = undefined;
        },
    },
});



const appDataInitialState = {
    practiceSession: {
        practice_sessionid: 0,
        round: 0,
        state: 'new',
        settings: {
        }
    },
    speakers: [],
    speech: null,
    tags: [],
    currentEvaluation: null,
};
const improvizAppSlice = createSlice({
    name: 'improvizApp',
    initialState: {data: appDataInitialState},
    reducers: {
        setData(state, action){
            state.data = action.payload;
        },
        setPracticeSession(state, action){
            state.data.practiceSession = action.payload;
        },
        setSpeech(state, action){
            state.data.speech = action.payload;
        },
        setSpeakers(state, action){
            state.data.speakers = action.payload;
        },
        clearData(state, action){
            state.data = appDataInitialState;
        },
    },
});

const store = configureStore({
    reducer: {
        improvizApp: improvizAppSlice.reducer,
        myTopics: myTopicsSlice.reducer,
        popupMessage: popupMessageSlice.reducer,
        serverTime: serverTimeSlice.reducer,
        user: userSlice.reducer,
    }
});

export {store};

export function getHttpClient(): HttpClient {
    const token = localStorage.getItem('token');
    return new HttpClient(token ? token : undefined);
};
