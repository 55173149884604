import React from 'react';
import configData from "../../config.json";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useTranslation} from "react-i18next";
import {getHttpClient} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../types/StateType";
import {isUserLoggedIn} from "../../helpers/UserHelper";
import {PopupMessageTypeEnum} from "../popupMessage/PopupMessageTypeEnum";
import {useNavigate} from "react-router-dom";

export function ForgotPasswordForm() {

    const dispatch = useDispatch();
    const {t} = useTranslation('common');
    const nav = useNavigate();

    const user = useSelector((state: IState) => {
        return state.user.user;
    });

    if (isUserLoggedIn(user)) {
        nav('/');
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const postBody = {
            username: data.get('username') as string,
        }

        getHttpClient().post(configData.apis.user.login, postBody)
            .then((response) => {
                if (response.data.token) {
                    dispatch({
                        type: 'user/setJwt',
                        payload: response.data.token,
                    });
                    nav('/');
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'popupMessage/setMessage',
                    payload: {
                        class: PopupMessageTypeEnum.ERROR,
                        title: t('login.errorTitle'),
                        message: t('login.errorMessage')
                    },
                });
            });
    }

    return(
        <div>
            <h1>{t('forgotPassword.formTitle')}</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="formGroup">
                    <Form.Label>{t('forgotPassword.username')}</Form.Label>
                    <Form.Control type="text" name="username" required />
                </Form.Group>
                <div className="btn-wrapper">
                    <Button variant="primary" type="submit">
                        {t('forgotPassword.submit')}
                    </Button>
                </div>
            </Form>
        </div>
    );
}
