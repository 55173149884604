import React from 'react';
import configData from "../../config.json";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useTranslation} from "react-i18next";
import {getHttpClient} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../types/StateType";
import {isUserLoggedIn} from "../../helpers/UserHelper";
import {PopupMessageTypeEnum} from "../popupMessage/PopupMessageTypeEnum";
import {useNavigate} from "react-router-dom";

export function LoginForm() {

    const dispatch = useDispatch();
    const {t} = useTranslation('common');
    const nav = useNavigate();

    const user = useSelector((state: IState) => {
        return state.user.user;
    });
    const userLoginComplete = useSelector((state: IState) => {
        return state.user.loginComplete;
    });
    const handleSubmit = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.target);
        if (validate(data)) {
            const postBody = {
                username: data.get('username') as string,
                password: data.get('password') as string,
            }

            getHttpClient().post(configData.apis.user.login, postBody)
                .then((response) => {
                    if (response.data.token) {
                        dispatch({
                            type: 'user/setUser',
                            payload: response.data,
                        });
                        localStorage.setItem('username', postBody.username);
                        localStorage.setItem('token', response.data.token);
                        nav('/');
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t('login.errorTitle'),
                            message: t('login.errorMessage')
                        },
                    });
                });
        }
    }

    const validate = (data: FormData) => {
        const isValid = true;
        return isValid;
    }

    if (!userLoginComplete || isUserLoggedIn(user)) {
        nav('/');
    }

    return(
        <div>
            <h1>{t('login.loginTitle')}</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="formGroup">
                    <Form.Label>{t('login.username')}</Form.Label>
                    <Form.Control type="text" name="username" required />
                </Form.Group>
                <Form.Group className="formGroup">
                    <Form.Label>{t('login.password')}</Form.Label>
                    <Form.Control type="password" name="password" />
                </Form.Group>
                <div className="btn-wrapper">
                    <Button variant="primary" type="submit">
                        {t('login.submit')}
                    </Button>
                </div>
            </Form>
        </div>
    );

}
