import React from 'react';
import {TemplateWrapper} from "../../templateWrapper/TemplateWrapper";
import { UserProfile } from './UserProfile';
import { MyTopics } from './MyTopics';
import './my-account.scss';


function MyAccount() {

    return (
        <div id="my-account">
            <TemplateWrapper>
                <UserProfile />
                <MyTopics />
            </TemplateWrapper>
        </div>
    );
}

export default MyAccount;
