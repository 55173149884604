import React, {useState} from 'react';
import configData from "../../config.json";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useTranslation} from "react-i18next";
import {getHttpClient} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../types/StateType";
import {isUserLoggedIn} from "../../helpers/UserHelper";
import {PopupMessageTypeEnum} from "../popupMessage/PopupMessageTypeEnum";
import {useNavigate} from "react-router-dom";

export function SignupForm() {

    const dispatch = useDispatch();
    const {t} = useTranslation('common');
    const nav = useNavigate();

    const user = useSelector((state: IState) => {
        return state.user.user;
    });
    const [file, setFile] = useState<Blob>();
    const handleSubmit = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.target);
        if (validate(data)) {
            const formData = new FormData();
            formData.append("image", file as Blob);
            formData.append("username", data.get('username') as string);
            formData.append("password", data.get('password') as string);
            formData.append("name", data.get('screenName') as string);

            const headers = {
                "content-type": "multipart/form-data",
            };
            getHttpClient().post(configData.apis.user.signup, formData, headers)
                .then((response) => {
                    if (response.data.result) {
                        const postBody = {
                            username: data.get('username') as string,
                            password: data.get('password') as string,
                        }
                        getHttpClient().post(configData.apis.user.login, postBody)
                            .then((response) => {
                                if (response.data.token) {
                                    dispatch({
                                        type: 'user/setJwt',
                                        payload: response.data.token,
                                    });
                                    localStorage.setItem('username', postBody.username);
                                    localStorage.setItem('password', postBody.password);
                                    nav('/');
                                }
                            })
                            .catch((error) => {
                                dispatch({
                                    type: 'popupMessage/setMessage',
                                    payload: {
                                        class: PopupMessageTypeEnum.ERROR,
                                        title: t('login.errorTitle'),
                                        message: t('login.errorMessage')
                                    },
                                });
                            });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t('messages.error'),
                            message: t(error.response.data.error)
                        },
                    });
                });
        }
    }

    const validate = (data: FormData) => {
        if (data.get('password') !== data.get('passwordConfirm')) {
            dispatch({
                type: 'popupMessage/setMessage',
                payload: {
                    class: PopupMessageTypeEnum.ERROR,
                    title: t('signup.passwordNotMatchTitle'),
                    message: t('signup.passwordNotMatchMessage')
                },
            });
        }
        return true;
    }

    if (isUserLoggedIn(user)) {
        nav('/');
        /*return (
            <div>
                <h1>{t('signup.isLoggedInTitle')}</h1>
                <p>{t('signup.isLoggedInMessage')}</p>
            </div>
        );*/
    }
    
    const fileChange = (event: any) => {
        setFile(event.target.files[0]);
    }

    return(
        <div>
            <h1>{t('signup.signupTitle')}</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="formGroup">
                    <Form.Label>{t('signup.username')}</Form.Label>
                    <Form.Control type="text" name="username" required />
                    <div className="description">{t('signup.usernameDescription')}</div>
                </Form.Group>
                <Form.Group className="formGroup">
                    <Form.Label>{t('signup.screenName')}</Form.Label>
                    <Form.Control type="text" name="screenName" required />
                    <div className="description">{t('signup.screenNameDescription')}</div>
                </Form.Group>
                <Form.Group className="formGroup">
                    <Form.Label>{t('signup.password')}</Form.Label>
                    <Form.Control type="password" name="password" required />
                </Form.Group>
                <Form.Group className="formGroup">
                    <Form.Label>{t('signup.passwordConfirm')}</Form.Label>
                    <Form.Control type="password" name="passwordConfirm" required />
                </Form.Group>
                <Form.Group className="formGroup">
                    <Form.Label>{t('signup.image')}</Form.Label>
                    <Form.Control type="file" name="image" onChange={fileChange} accept=".gif,.jpg,.jpeg,.png"/>
                    <div className="description">{t('signup.imageDescription')}</div>
                </Form.Group>
                <div className="btn-wrapper">
                    <Button variant="primary" type="submit">
                        {t('signup.submit')}
                    </Button>
                </div>
            </Form>
        </div>
    );

}
