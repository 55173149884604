import React, {useEffect, useState} from 'react';
import './App.scss';
import configData from "./config.json";
import {useDispatch} from "react-redux";
import {getHttpClient} from "./store";
import {Route, Routes} from "react-router-dom";
import HomePage from "./components/pages/homePage/HomePage";
import LoginPage from "./components/pages/loginPage/LoginPage";
import ForgotPasswordPage from "./components/pages/forgotPasswordPage/ForgotPasswordPage";
import SignupPage from "./components/pages/signupPage/SignupPage";
import 'bootstrap/dist/css/bootstrap.css';
import Improviz from './components/improviz/Improviz';
import Admin from './components/admin/Admin';
import MyAccount from './components/pages/myAccount/MyAccount';
import { getErrorMessage } from './helpers/ServiceErrorHelper';
import { PopupMessageTypeEnum } from './components/popupMessage/PopupMessageTypeEnum';
import { useTranslation } from 'react-i18next';


//const UserContext = React.createContext('');


function App() {

    const { i18n } = useTranslation('common');
  const {t} = useTranslation('common');
  const dispatch = useDispatch();


  useEffect(() => {
      getHttpClient().get(configData.apis.practiceSession.serverTime)
          .then((response) => {
              dispatch({
                  type: 'serverTime/setServerTime',
                  payload: response.data.time,
              });
          })
          .catch((error) => {
              const errorMessage = getErrorMessage(error);
              if (errorMessage.title) {
                  dispatch({
                      type: 'popupMessage/setMessage',
                      payload: {
                          class: PopupMessageTypeEnum.ERROR,
                          title: t(errorMessage.title),
                          message: t(errorMessage.message),
                      },
                  });
              }
          });
  }, []);

  useEffect(() => {
    getHttpClient().get(configData.apis.user.load)
        .then((response) => {
          localStorage.setItem('token', response.data.token);
          dispatch({
            type: 'user/setUser',
            payload: response.data,
          });
        })
        .catch((error) => {
            const errorMessage = getErrorMessage(error);
            if (errorMessage.title) {
                dispatch({
                    type: 'popupMessage/setMessage',
                    payload: {
                        class: PopupMessageTypeEnum.ERROR,
                        title: t(errorMessage.title),
                        message: t(errorMessage.message),
                    },
                });
            }
        });
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem('language');
    if (lang) {
        i18n.changeLanguage(lang);
    }
    const psid = localStorage.getItem('practiceSessionId');
    if (psid) {
      const postBody = {
          practice_sessionid: psid,
          lang: lang
      }
      getHttpClient().post(configData.apis.practiceSession.get, postBody)
          .then((response) => {
            dispatch({
              type: 'improvizApp/setData',
              payload: response.data,
            });
          })
          .catch((error) => {
              const errorMessage = getErrorMessage(error);
              if (errorMessage.title) {
                  dispatch({
                      type: 'popupMessage/setMessage',
                      payload: {
                          class: PopupMessageTypeEnum.ERROR,
                          title: t(errorMessage.title),
                          message: t(errorMessage.message),
                      },
                  });
              }
          });
    }
  }, []);



  return (
        <>
          <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="admin" element={<Admin />} />
              <Route path="practice" element={<Improviz />} />
              <Route path="my-account" element={<MyAccount />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="signup" element={<SignupPage />} />
          </Routes>
        </>
  );
}

export default App;
