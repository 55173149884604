
export function getErrorMessage(error: {code: string, response: {status: number}}): {title: string, message: string} {
    const message = {
        title: "",
        message: "",
    };
    if (error.code === "ERR_NETWORK") {
        message.title = 'error.networkTitle';
        message.message = 'error.networkMessage';
    }
    else if (error.response.status === 403) {
        message.title = 'error.403Title';
        message.message = 'error.403Message';
    }
    return message;
}
