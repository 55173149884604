import { useTranslation } from "react-i18next";
import './participants-settings.scss';
import { getHttpClient } from "../../store";
import configData from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../types/StateType";
import { Pause, PauseBtn, PencilFill, PlayBtn, PlusSquareFill, XCircleFill } from "react-bootstrap-icons";
import { ChangeEvent, useState } from "react";
import { PopupMessageTypeEnum } from "../popupMessage/PopupMessageTypeEnum";
import { ISpeaker } from "../../types/SpeakerType";
import { getErrorMessage } from "../../helpers/ServiceErrorHelper";


export function ParticipantsSettings() {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const improvizState = useSelector((state: IState) => {
        return state.improvizApp.data;
    });
    const [showAddForm, setShowAddForm] = useState<boolean>(false);
    const [editSpeaker, setEditSpeaker] = useState<number>(0);
    const [editName, setEditName] = useState<string>('');

    const [formData, setFormData] = useState({
        name: '',
      });
    
      const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = new FormData(e.target);
        if (validate(data)) {
            const postBody = {
                practice_sessionid: improvizState.practiceSession.practice_sessionid,
                name: data.get('name') as string,
            }
            

            getHttpClient().post(configData.apis.practiceSession.addSpeaker, postBody)
                .then((response) => {
                    if (response.data.speakers.length) {
                        setFormData({
                            name: ""
                        });
                        dispatch({
                          type: 'improvizApp/setSpeakers',
                          payload: response.data.speakers.sort((a :ISpeaker, b: ISpeaker) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    if (errorMessage.title) {
                        dispatch({
                            type: 'popupMessage/setMessage',
                            payload: {
                                class: PopupMessageTypeEnum.ERROR,
                                title: t(errorMessage.title),
                                message: t(errorMessage.message),
                            },
                        });
                    }
                });
        }
      };

    const validate = (data: FormData) => {
        // Make sure speaker does not already exist
        if (improvizState.speakers.find((x) => x.name === data.get('name'))){
            return false;
        }
        return true;
    }

    const showEditName = (speaker: ISpeaker) => {
        if (editSpeaker === speaker.speakerid) {
            setEditName("");
            setEditSpeaker(0);
        }
        else {
            setEditName(speaker.name);
            setEditSpeaker(speaker.speakerid);
        }
    }

    
    const handleEditName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditName(e.target.value);
      };

      
      const handleEditNameSave = () => {
        if (validateEditName()) {
            const postBody = {
                practice_sessionid: improvizState.practiceSession.practice_sessionid,
                speakerid: editSpeaker,
                name: editName,
            }

            getHttpClient().post(configData.apis.speaker.rename, postBody)
                .then((response) => {
                    if (response.data.speakers.length) {
                        dispatch({
                          type: 'improvizApp/setSpeakers',
                          payload: response.data.speakers.sort((a :ISpeaker, b: ISpeaker) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    if (errorMessage.title) {
                        dispatch({
                            type: 'popupMessage/setMessage',
                            payload: {
                                class: PopupMessageTypeEnum.ERROR,
                                title: t(errorMessage.title),
                                message: t(errorMessage.message),
                            },
                        });
                    }
                });
        }
      };

    const validateEditName = () => {
        if (improvizState.speakers.find((x) => x.name === editName)){
            return false;
        }
        return true;
    }

    const pause = (speakerid: number) => {
        const postBody = {
            practice_sessionid: improvizState.practiceSession.practice_sessionid,
            speakerid: speakerid
        }
        getHttpClient().post(configData.apis.speaker.pause, postBody)
            .then((response) => {
                dispatch({
                    type: 'improvizApp/setSpeakers',
                    payload: response.data.speakers.sort((a :ISpeaker, b: ISpeaker) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                  });
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }

    const pausePlay = (speaker: ISpeaker) => {
        return speaker.status == 1 ? ' play' : ' pause';
    }
    const evenOdd = (i: number) => {
        return i % 2 == 0 ? ' even' : ' odd';
    }

    return (
        
        <div id="participants" className="settings-form">
            <h2>
                <label>{t('improviz.participants')}</label>
                <span className="plus"><PlusSquareFill onClick={(e) => setShowAddForm(!showAddForm)}/></span>
            </h2>
            <div className="content">
                {showAddForm &&
                    <form onSubmit={handleSubmit} id="add-participant-form">
                        <span id="close-add-participants"><XCircleFill onClick={(e) => setShowAddForm(false)}/></span>
                        <p>{t("improviz.addParticipant")}</p>
                        <div className="form-group">
                            <label htmlFor="name">{t("improviz.name")}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div id="submit">
                            <button type="submit" className="btn btn-primary">{t("improviz.add")}</button>
                        </div>
                    </form>
                }
                <div id="participants-list">
                    <ul>
                    {improvizState.speakers.map((speaker, i) => (
                        <li key={i} className={"speaker" + pausePlay(speaker) + evenOdd(i)}>
                            {editSpeaker === speaker.speakerid &&
                                <form id="edit-participant-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={editName}
                                        onChange={handleEditName}
                                        onBlur={handleEditNameSave}
                                        required
                                        autoFocus
                                    />
                                </div>
                            </form>
                            }
                            {editSpeaker !== speaker.speakerid &&
                                <span>
                                    {speaker.name}
                                    {speaker.status === 0 &&
                                        <Pause />
                                    }
                                </span>
                            }
                            <div className="speaker-buttons">
                                {speaker.status == 1 &&
                                <span className="speaker-action" onClick={(e) => pause(speaker.speakerid)}>
                                    <PauseBtn />
                                </span>
                                }
                                {speaker.status == 0 &&
                                    <span className="speaker-action" onClick={(e) => pause(speaker.speakerid)}>
                                        <PlayBtn />
                                    </span>
                                }
                                <span className="speaker-action" onClick={(e) => showEditName(speaker)}>
                                    <PencilFill />
                                </span>
                            </div>
                        </li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
