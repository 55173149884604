import React, {useEffect, useState} from 'react';
import { getHttpClient } from '../../store';
import configData from "../../config.json";
import { IPracticeSession } from '../../types/PracticeSessionType';
import { getErrorMessage } from '../../helpers/ServiceErrorHelper';
import { PopupMessageTypeEnum } from '../popupMessage/PopupMessageTypeEnum';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function PracticeSessions() {

    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const [count, setCount] = useState<number>(0);
    const [latestSessions, setLatestSessions] = useState<{session: IPracticeSession, participants: string[]}[]>([]);

    useEffect(() => {
        getHttpClient().get(configData.apis.admin.practiceSessions)
            .then((response) => {
                setCount(response.data.count);
                setLatestSessions(response.data.latest);
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }, []);

    const evenOdd = (i: number) => {
        return i % 2 == 0 ? ' even' : ' odd';
    }

    return(
        <div id="sessions-count">
            <h2>Practice Sessions: {count}</h2>
            <ul id="sessions-list">
                {latestSessions.map((session, i) => (
                    <li key={session.session.practice_sessionid} className={"session" + evenOdd(i)}>
                        <span className="session-date">{session.session.created}: {session.participants.join(', ')}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}
