import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from '../../../types/StateType';

export function UserProfile() {

    const user = useSelector((state: IState) => {
        return state.user.user;
    });

    return(
        <div id="user-profile">
            <h1>{user?.name}</h1>
        </div>
    );
}
