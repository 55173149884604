import axios from "axios";


export class HttpClient {

    jwt?: string;

    constructor(jwt?: string) {
        this.jwt = jwt;
    }

    get(url: string, headers?: any) {
        return axios.get(url, this.buildConfig(headers));
    }

    post(url: string, data: any, headers?: any) {
        return axios.post(url, data, this.buildConfig(headers));
    }

    buildConfig(headers: any) {
        return {
            headers: this.buildHeaders(headers),
        };
    }

    buildHeaders(headers: any) {
        if (!headers && (!this.jwt || this.jwt === 'undefined')) {
            return null;
        }
        if (!headers) {
            headers = {};
        }
        if (this.jwt && typeof  this.jwt !== 'undefined') {
            headers.Authorization = `Bearer ${this.jwt}`;
        }
        return headers;
    }
}