import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../types/StateType';
import { useTranslation } from 'react-i18next';
import { getHttpClient } from '../../../store';
import configData from "../../../config.json";
import './my-topics.scss';
import { getErrorMessage } from '../../../helpers/ServiceErrorHelper';
import { PopupMessageTypeEnum } from '../../popupMessage/PopupMessageTypeEnum';

export function MyTopics() {

    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const myTopics = useSelector((state: IState) => {
        return state.myTopics.myTopics;
    });

    /*if (myTopics && myTopics.length > 0) {
        myTopics = myTopics.sort((a :ITopic, b: ITopic) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
    }*/

    useEffect(() => {
        getHttpClient().get(configData.apis.myTopics.get)
            .then((response) => {
                dispatch({
                    type: 'myTopics/setMyTopics',
                    payload: response.data.topics,
                });
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                if (errorMessage.title) {
                    dispatch({
                        type: 'popupMessage/setMessage',
                        payload: {
                            class: PopupMessageTypeEnum.ERROR,
                            title: t(errorMessage.title),
                            message: t(errorMessage.message),
                        },
                    });
                }
            });
    }, []);
    
    const evenOdd = (i: number) => {
        return i % 2 == 0 ? ' even' : ' odd';
    }

    return(
        <div id="my-topics" className="content-box">
            <h2>{t('myTopics.myTopics')}</h2>
            {(!myTopics || (myTopics && myTopics.length === 0)) &&
                <p>{t('myTopics.noTopicsMessage')}</p>
            }
            {myTopics && myTopics.length > 0 &&
                <ul id="topics-list">
                    {myTopics.map((topic, i) => (
                        <li key={topic.topicid} className={"topic" + evenOdd(i)}>
                            <span className="topic-title">{topic.title}</span>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}
