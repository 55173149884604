import { ColourFlag } from "../enums/ColourFlagEnum";
import { ImpState } from "../enums/ImpStateEnum";
import {IAppData} from "../types/AppDataType";

export class SpeechTimeHelper {
    improvizState: IAppData;
    timeOffset: number;

    constructor(improvizState: IAppData, timeOffset: number) {
        this.improvizState = improvizState
        this.timeOffset = timeOffset;
    }

    getElapsedTime(): number|null {
        if (this.improvizState.speech && this.improvizState.speech.eval_started) {
            const now = new Date().getTime();
            const started = new Date(this.improvizState.speech.eval_started).getTime();
            return Math.round((now - started + this.timeOffset) / 1000) - this.improvizState.speech.eval_pause_time;
        }
        if (this.improvizState.speech && this.improvizState.speech.started) {
            const now = new Date().getTime();
            const started = new Date(this.improvizState.speech.started).getTime();
            return Math.round((now - started + this.timeOffset) / 1000) - this.improvizState.speech.pause_time;
        }
        return null;
    }

    getRemainingTime(): number|null {
        const elapsed = this.getElapsedTime();
        const allowedTime = this.getAllowedTime();
        if (elapsed === null || allowedTime === null) {
            return null;
        }
        return allowedTime - elapsed;
    }

    getAllowedTime(): number|null {
        if (this.improvizState.speech && this.improvizState.speech.eval_started) {
            return this.improvizState.practiceSession.settings.feedback_max;
        }
        if (this.improvizState.speech) {
            if (this.improvizState.speech.topic.settings.custom_timings) {
                return this.improvizState.speech.topic.settings.max_time;
            }
            return this.improvizState.practiceSession.settings.max_time;
        }
        return null;
    }

    getColour(): string|null {
        if (this.improvizState.practiceSession.state === ImpState.READY) {
            return ColourFlag.WHITE;
        }
        const rem = this.getElapsedTime();
        if (rem === null) {
            return ColourFlag.WHITE;
        }
        
        if (this.improvizState.speech && this.improvizState.speech.eval_started) {

            if (rem < this.improvizState.practiceSession.settings.feedback_green) {
                return ColourFlag.WHITE;
            }
            if (rem < this.improvizState.practiceSession.settings.feedback_yellow) {
                return ColourFlag.GREEN;
            }
            if (rem < this.improvizState.practiceSession.settings.feedback_red) {
                return ColourFlag.YELLOW;
            }
            if (rem < this.improvizState.practiceSession.settings.feedback_max) {
                return ColourFlag.RED;
            }
            if (rem >= this.improvizState.practiceSession.settings.feedback_max) {
                return ColourFlag.OVER;
            }
        }

        if (this.improvizState.speech.topic.settings.custom_timings) {
            if (rem < this.improvizState.speech.topic.settings.green_time) {
                return ColourFlag.WHITE;
            }
            if (rem < this.improvizState.speech.topic.settings.yellow_time) {
                return ColourFlag.GREEN;
            }
            if (rem < this.improvizState.speech.topic.settings.red_time) {
                return ColourFlag.YELLOW;
            }
            if (rem < this.improvizState.speech.topic.settings.max_time) {
                return ColourFlag.RED;
            }
            if (rem >= this.improvizState.speech.topic.settings.max_time) {
                return ColourFlag.OVER;
            }
        }
        if (rem < this.improvizState.practiceSession.settings.green_time) {
            return ColourFlag.WHITE;
        }
        if (rem < this.improvizState.practiceSession.settings.yellow_time) {
            return ColourFlag.GREEN;
        }
        if (rem < this.improvizState.practiceSession.settings.red_time) {
            return ColourFlag.YELLOW;
        }
        if (rem < this.improvizState.practiceSession.settings.max_time) {
            return ColourFlag.RED;
        }
        if (rem >= this.improvizState.practiceSession.settings.max_time) {
            return ColourFlag.OVER;
        }
        return ColourFlag.WHITE;
    }


}
