import React, {useState} from 'react';
import './popup-message.scss';
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../types/StateType";
import {XCircle} from "react-bootstrap-icons";


function PopupMessage() {

    const dispatch = useDispatch();

    const [isClosing, setIsClosing] = useState(false);
    //const [timer, setTimer] = useState<NodeJS.Timeout>();

    const message = useSelector((state: IState) => {
        return state.popupMessage;
    });

    const closePopup = () => {
        //clearTimeout(timer);
        setIsClosing(true);
        dispatch({
            type: 'popupMessage/clearMessage',
            payload: {},
        });
        setIsClosing(false);
    }
    const fadeOutPopup = () => {
        setIsClosing(true);
        setTimeout(function(){
            dispatch({
                type: 'popupMessage/clearMessage',
                payload: {},
            });
            setIsClosing(false);
        }, 2000);
    }

    
    if (message && message.class) {

        //clearTimeout(timer);
        const t = setTimeout(function(){
            fadeOutPopup();
        }, 8000);
        //setTimer(t);

        const fadeClass = isClosing ? 'fadeOut' : 'fadeIn';
        return (
            <div id="popupMessage" className={fadeClass + " " + message.class}>
                <h2>{message.title}</h2>
                <p>{message.message}</p>
                <span className="close" onClick={closePopup}><XCircle/></span>
            </div>
        );
    }
    /*if (isClosing) {
        return (
            <div id="popupMessage">
            </div>
        );
    }*/
    return (
        <div id="popupMessage">
        </div>
    );
}

export default PopupMessage;
