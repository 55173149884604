import { useTranslation } from "react-i18next";
import { getHttpClient } from "../../store";
import configData from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../types/StateType";
import { useState } from "react";
import { PopupMessageTypeEnum } from "../popupMessage/PopupMessageTypeEnum";
import { TimeInput } from "./TimeInput";
import { TimeConversionHelper } from "../../helpers/TimeConversionHelper";
import './speech-timings.scss';
import { getErrorMessage } from "../../helpers/ServiceErrorHelper";

export function SpeechTimings() {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const improvizState = useSelector((state: IState) => {
        return state.improvizApp.data;
    });

    const [formData, setFormData] = useState({
        green: String(improvizState.practiceSession.settings.green_time),
        yellow: String(improvizState.practiceSession.settings.yellow_time),
        red: String(improvizState.practiceSession.settings.red_time),
        max: String(improvizState.practiceSession.settings.max_time),
      });
    
      const handleChange = (colour: string, value: string) => {
        setFormData({
          ...formData,
          [colour]: value
        });
      };
    
      const handleSubmit = () => {
        if (validate()) {
            const postBody = {
                practice_sessionid: improvizState.practiceSession.practice_sessionid,
                data: formData,
            }

            getHttpClient().post(configData.apis.practiceSession.setTimings, postBody)
                .then((response) => {
                    if (response.data.practiceSession) {
                        dispatch({
                          type: 'improvizApp/setPracticeSession',
                          payload: response.data.practiceSession,
                        });
                    }
                })
                .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    if (errorMessage.title) {
                        dispatch({
                            type: 'popupMessage/setMessage',
                            payload: {
                                class: PopupMessageTypeEnum.ERROR,
                                title: t(errorMessage.title),
                                message: t(errorMessage.message),
                            },
                        });
                    }
                });
        }
      };

    const validate = () => {
        const greenSeconds = TimeConversionHelper.timeToSeconds(String(formData.green));
        const yellowSeconds = TimeConversionHelper.timeToSeconds(String(formData.yellow));
        const redSeconds = TimeConversionHelper.timeToSeconds(String(formData.red));
        // Make sure speaker does not already exist
        if (greenSeconds > yellowSeconds){
            return false;
        }
        if (yellowSeconds > redSeconds){
            return false;
        }
        return true;
    }

    return (
        
        <div id="speech-timings" className="settings-form">
            <h2>
                <label>{t('improviz.speechTimings')}</label>
            </h2>
            <div className="content">
                <form id="speech-timings-form">
                    <div className="form-group green">
                        <label htmlFor="name">{t("improviz.green")}</label>
                        <TimeInput
                            className="green"
                            id="time-green"
                            name="green"
                            value={formData.green}
                            max={TimeConversionHelper.timeToSeconds(formData.yellow)}
                            onChange={(e) => handleChange('green', e.target.value)}
                            onBlur={handleSubmit}
                            required
                        />
                    </div>
                    <div className="form-group yellow">
                        <label htmlFor="name">{t("improviz.yellow")}</label>
                        <TimeInput
                            className="yellow"
                            id="time-yellow"
                            name="yellow"
                            value={formData.yellow}
                            min={TimeConversionHelper.timeToSeconds(formData.green)}
                            max={TimeConversionHelper.timeToSeconds(formData.red)}
                            onChange={(e) => handleChange('yellow', e.target.value)}
                            onBlur={handleSubmit}
                            required
                        />
                    </div>
                    <div className="form-group red">
                        <label htmlFor="name">{t("improviz.red")}</label>
                        <TimeInput
                            className="red"
                            id="time-red"
                            name="red"
                            value={formData.red}
                            min={TimeConversionHelper.timeToSeconds(formData.yellow)}
                            onChange={(e) => handleChange('red', e.target.value)}
                            onBlur={handleSubmit}
                            required
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
